
export default {
  name: 'DefaultLayout',
  data() {
    return {
      title: 'TLCA',
    }
  },
  computed: {
    showBreadCrumb() {
      return this.$route.name !== 'index'
    },
  },
}
