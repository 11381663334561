
export default {
  name: 'SignInBtn',
  data() {
    return {
      dialog: false,
      formBusy: false,
    }
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.$refs.form.reset()
      }
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
  },
}
