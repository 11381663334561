
export default {
  name: 'UserMenu',
  data() {
    return {
      items: [
        {
          title: this.$t('general.dashboard'),
          to: { name: 'dashboard' },
        },
        {
          title: this.$t('user.profile'),
          to: {
            name: 'profiles-username',
            params: { username: this.$auth.user.username },
          },
        },
      ],
    }
  },
  methods: {
    async signOut() {
      this.$nuxt.$loading.start()
      await this.$auth.logout()
    },
  },
}
