import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8260043c = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _2006c10b = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _5725e938 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _d7944ad2 = () => interopDefault(import('../pages/learn/index.vue' /* webpackChunkName: "pages/learn/index" */))
const _4b8479b9 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7fe531f0 = () => interopDefault(import('../pages/manage/index.vue' /* webpackChunkName: "pages/manage/index" */))
const _1d1b94ce = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _2b07b4dc = () => interopDefault(import('../pages/programs/index.vue' /* webpackChunkName: "pages/programs/index" */))
const _724c4b88 = () => interopDefault(import('../pages/teach/index.vue' /* webpackChunkName: "pages/teach/index" */))
const _2d25461e = () => interopDefault(import('../pages/admin/competencies/index.vue' /* webpackChunkName: "pages/admin/competencies/index" */))
const _37fea84b = () => interopDefault(import('../pages/admin/courses/index.vue' /* webpackChunkName: "pages/admin/courses/index" */))
const _33d04591 = () => interopDefault(import('../pages/admin/instances/index.vue' /* webpackChunkName: "pages/admin/instances/index" */))
const _0420958e = () => interopDefault(import('../pages/admin/partners/index.vue' /* webpackChunkName: "pages/admin/partners/index" */))
const _4a60670f = () => interopDefault(import('../pages/admin/programs.vue' /* webpackChunkName: "pages/admin/programs" */))
const _fe4c26ca = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _7c2b0000 = () => interopDefault(import('../pages/learn/courses/index.vue' /* webpackChunkName: "pages/learn/courses/index" */))
const _55a66ab8 = () => interopDefault(import('../pages/manage/competencies/index.vue' /* webpackChunkName: "pages/manage/competencies/index" */))
const _8028e91e = () => interopDefault(import('../pages/manage/courses/index.vue' /* webpackChunkName: "pages/manage/courses/index" */))
const _79d01728 = () => interopDefault(import('../pages/manage/partners/index.vue' /* webpackChunkName: "pages/manage/partners/index" */))
const _4730a7ec = () => interopDefault(import('../pages/manage/programs/index.vue' /* webpackChunkName: "pages/manage/programs/index" */))
const _693deff1 = () => interopDefault(import('../pages/teach/courses/index.vue' /* webpackChunkName: "pages/teach/courses/index" */))
const _f24991f4 = () => interopDefault(import('../pages/manage/competencies/create.vue' /* webpackChunkName: "pages/manage/competencies/create" */))
const _653a2d6d = () => interopDefault(import('../pages/manage/courses/create.vue' /* webpackChunkName: "pages/manage/courses/create" */))
const _3031ced4 = () => interopDefault(import('../pages/manage/partners/create.vue' /* webpackChunkName: "pages/manage/partners/create" */))
const _72cebf5c = () => interopDefault(import('../pages/manage/programs/create.vue' /* webpackChunkName: "pages/manage/programs/create" */))
const _fc4652bc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _779413fd = () => interopDefault(import('../pages/learn/courses/_code/index.vue' /* webpackChunkName: "pages/learn/courses/_code/index" */))
const _62d294b5 = () => interopDefault(import('../pages/manage/competencies/_code/index.vue' /* webpackChunkName: "pages/manage/competencies/_code/index" */))
const _472086ae = () => interopDefault(import('../pages/manage/courses/_code/index.vue' /* webpackChunkName: "pages/manage/courses/_code/index" */))
const _4e75a5b6 = () => interopDefault(import('../pages/manage/partners/_code/index.vue' /* webpackChunkName: "pages/manage/partners/_code/index" */))
const _1fbf1ae9 = () => interopDefault(import('../pages/manage/programs/_code/index.vue' /* webpackChunkName: "pages/manage/programs/_code/index" */))
const _e594e9a4 = () => interopDefault(import('../pages/teach/courses/_code/index.vue' /* webpackChunkName: "pages/teach/courses/_code/index" */))
const _93148642 = () => interopDefault(import('../pages/learn/courses/_code/assessments/index.vue' /* webpackChunkName: "pages/learn/courses/_code/assessments/index" */))
const _96cd983a = () => interopDefault(import('../pages/learn/courses/_code/competencies/index.vue' /* webpackChunkName: "pages/learn/courses/_code/competencies/index" */))
const _0ba3bc25 = () => interopDefault(import('../pages/learn/courses/_code/evaluations/index.vue' /* webpackChunkName: "pages/learn/courses/_code/evaluations/index" */))
const _2219a3f7 = () => interopDefault(import('../pages/manage/competencies/_code/edit.vue' /* webpackChunkName: "pages/manage/competencies/_code/edit" */))
const _5502a3e0 = () => interopDefault(import('../pages/manage/courses/_code/assessments/index.vue' /* webpackChunkName: "pages/manage/courses/_code/assessments/index" */))
const _31b90ede = () => interopDefault(import('../pages/manage/courses/_code/edit.vue' /* webpackChunkName: "pages/manage/courses/_code/edit" */))
const _62d38f2b = () => interopDefault(import('../pages/manage/courses/_code/groups/index.vue' /* webpackChunkName: "pages/manage/courses/_code/groups/index" */))
const _30852a8e = () => interopDefault(import('../pages/manage/courses/_code/registrations/index.vue' /* webpackChunkName: "pages/manage/courses/_code/registrations/index" */))
const _07298743 = () => interopDefault(import('../pages/manage/programs/_code/edit.vue' /* webpackChunkName: "pages/manage/programs/_code/edit" */))
const _3a4b0898 = () => interopDefault(import('../pages/manage/programs/_code/registrations/index.vue' /* webpackChunkName: "pages/manage/programs/_code/registrations/index" */))
const _1c9e1ae0 = () => interopDefault(import('../pages/teach/courses/_code/assessments/index.vue' /* webpackChunkName: "pages/teach/courses/_code/assessments/index" */))
const _0194a095 = () => interopDefault(import('../pages/teach/courses/_code/calendar/index.vue' /* webpackChunkName: "pages/teach/courses/_code/calendar/index" */))
const _46def1d6 = () => interopDefault(import('../pages/teach/courses/_code/evaluations/index.vue' /* webpackChunkName: "pages/teach/courses/_code/evaluations/index" */))
const _12709cf9 = () => interopDefault(import('../pages/teach/courses/_code/learners/index.vue' /* webpackChunkName: "pages/teach/courses/_code/learners/index" */))
const _020b5eae = () => interopDefault(import('../pages/manage/courses/_code/assessments/create.vue' /* webpackChunkName: "pages/manage/courses/_code/assessments/create" */))
const _8698d8b0 = () => interopDefault(import('../pages/teach/courses/_code/evaluations/create.vue' /* webpackChunkName: "pages/teach/courses/_code/evaluations/create" */))
const _5057504a = () => interopDefault(import('../pages/learn/courses/_code/assessments/_id/index.vue' /* webpackChunkName: "pages/learn/courses/_code/assessments/_id/index" */))
const _54483c90 = () => interopDefault(import('../pages/learn/courses/_code/evaluations/_id/index.vue' /* webpackChunkName: "pages/learn/courses/_code/evaluations/_id/index" */))
const _1b9b7e0a = () => interopDefault(import('../pages/manage/courses/_code/assessments/_id/index.vue' /* webpackChunkName: "pages/manage/courses/_code/assessments/_id/index" */))
const _d20b4e10 = () => interopDefault(import('../pages/teach/courses/_code/assessments/_id.vue' /* webpackChunkName: "pages/teach/courses/_code/assessments/_id" */))
const _6e9f31c1 = () => interopDefault(import('../pages/teach/courses/_code/evaluations/_id/index.vue' /* webpackChunkName: "pages/teach/courses/_code/evaluations/_id/index" */))
const _3e62f6df = () => interopDefault(import('../pages/teach/courses/_code/learners/_username/index.vue' /* webpackChunkName: "pages/teach/courses/_code/learners/_username/index" */))
const _209fb223 = () => interopDefault(import('../pages/learn/courses/_code/assessments/_id/requestEvaluation.vue' /* webpackChunkName: "pages/learn/courses/_code/assessments/_id/requestEvaluation" */))
const _fcdf521e = () => interopDefault(import('../pages/manage/courses/_code/assessments/_id/edit.vue' /* webpackChunkName: "pages/manage/courses/_code/assessments/_id/edit" */))
const _32ff356b = () => interopDefault(import('../pages/teach/courses/_code/evaluations/_id/edit.vue' /* webpackChunkName: "pages/teach/courses/_code/evaluations/_id/edit" */))
const _18a5467e = () => interopDefault(import('../pages/teach/courses/_code/learners/_username/assessments/index.vue' /* webpackChunkName: "pages/teach/courses/_code/learners/_username/assessments/index" */))
const _7c924105 = () => interopDefault(import('../pages/learn/courses/_code/assessments/_id/take/_iid.vue' /* webpackChunkName: "pages/learn/courses/_code/assessments/_id/take/_iid" */))
const _0684c92e = () => interopDefault(import('../pages/teach/courses/_code/learners/_username/assessments/_id.vue' /* webpackChunkName: "pages/teach/courses/_code/learners/_username/assessments/_id" */))
const _21d17205 = () => interopDefault(import('../pages/courses/_code.vue' /* webpackChunkName: "pages/courses/_code" */))
const _1ee645c8 = () => interopDefault(import('../pages/partners/_code.vue' /* webpackChunkName: "pages/partners/_code" */))
const _47ee7b9a = () => interopDefault(import('../pages/profiles/_username/index.vue' /* webpackChunkName: "pages/profiles/_username/index" */))
const _277252e8 = () => interopDefault(import('../pages/programs/_code.vue' /* webpackChunkName: "pages/programs/_code" */))
const _3ec8ceb9 = () => interopDefault(import('../pages/profiles/_username/edit.vue' /* webpackChunkName: "pages/profiles/_username/edit" */))
const _c7478df8 = () => interopDefault(import('../pages/profiles/_username/passwordreset/_token.vue' /* webpackChunkName: "pages/profiles/_username/passwordreset/_token" */))
const _39ca8770 = () => interopDefault(import('../pages/profiles/_username/_emailConfirmationToken.vue' /* webpackChunkName: "pages/profiles/_username/_emailConfirmationToken" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin/",
    component: _8260043c,
    pathToRegexpOptions: {"strict":true},
    name: "admin"
  }, {
    path: "/courses/",
    component: _2006c10b,
    pathToRegexpOptions: {"strict":true},
    name: "courses"
  }, {
    path: "/dashboard/",
    component: _5725e938,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard"
  }, {
    path: "/learn/",
    component: _d7944ad2,
    pathToRegexpOptions: {"strict":true},
    name: "learn"
  }, {
    path: "/login/",
    component: _4b8479b9,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/manage/",
    component: _7fe531f0,
    pathToRegexpOptions: {"strict":true},
    name: "manage"
  }, {
    path: "/partners/",
    component: _1d1b94ce,
    pathToRegexpOptions: {"strict":true},
    name: "partners"
  }, {
    path: "/programs/",
    component: _2b07b4dc,
    pathToRegexpOptions: {"strict":true},
    name: "programs"
  }, {
    path: "/teach/",
    component: _724c4b88,
    pathToRegexpOptions: {"strict":true},
    name: "teach"
  }, {
    path: "/admin/competencies/",
    component: _2d25461e,
    pathToRegexpOptions: {"strict":true},
    name: "admin-competencies"
  }, {
    path: "/admin/courses/",
    component: _37fea84b,
    pathToRegexpOptions: {"strict":true},
    name: "admin-courses"
  }, {
    path: "/admin/instances/",
    component: _33d04591,
    pathToRegexpOptions: {"strict":true},
    name: "admin-instances"
  }, {
    path: "/admin/partners/",
    component: _0420958e,
    pathToRegexpOptions: {"strict":true},
    name: "admin-partners"
  }, {
    path: "/admin/programs/",
    component: _4a60670f,
    pathToRegexpOptions: {"strict":true},
    name: "admin-programs"
  }, {
    path: "/admin/users/",
    component: _fe4c26ca,
    pathToRegexpOptions: {"strict":true},
    name: "admin-users"
  }, {
    path: "/learn/courses/",
    component: _7c2b0000,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses"
  }, {
    path: "/manage/competencies/",
    component: _55a66ab8,
    pathToRegexpOptions: {"strict":true},
    name: "manage-competencies"
  }, {
    path: "/manage/courses/",
    component: _8028e91e,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses"
  }, {
    path: "/manage/partners/",
    component: _79d01728,
    pathToRegexpOptions: {"strict":true},
    name: "manage-partners"
  }, {
    path: "/manage/programs/",
    component: _4730a7ec,
    pathToRegexpOptions: {"strict":true},
    name: "manage-programs"
  }, {
    path: "/teach/courses/",
    component: _693deff1,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses"
  }, {
    path: "/manage/competencies/create/",
    component: _f24991f4,
    pathToRegexpOptions: {"strict":true},
    name: "manage-competencies-create"
  }, {
    path: "/manage/courses/create/",
    component: _653a2d6d,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-create"
  }, {
    path: "/manage/partners/create/",
    component: _3031ced4,
    pathToRegexpOptions: {"strict":true},
    name: "manage-partners-create"
  }, {
    path: "/manage/programs/create/",
    component: _72cebf5c,
    pathToRegexpOptions: {"strict":true},
    name: "manage-programs-create"
  }, {
    path: "/",
    component: _fc4652bc,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/learn/courses/:code/",
    component: _779413fd,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code"
  }, {
    path: "/manage/competencies/:code/",
    component: _62d294b5,
    pathToRegexpOptions: {"strict":true},
    name: "manage-competencies-code"
  }, {
    path: "/manage/courses/:code/",
    component: _472086ae,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code"
  }, {
    path: "/manage/partners/:code/",
    component: _4e75a5b6,
    pathToRegexpOptions: {"strict":true},
    name: "manage-partners-code"
  }, {
    path: "/manage/programs/:code/",
    component: _1fbf1ae9,
    pathToRegexpOptions: {"strict":true},
    name: "manage-programs-code"
  }, {
    path: "/teach/courses/:code/",
    component: _e594e9a4,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code"
  }, {
    path: "/learn/courses/:code/assessments/",
    component: _93148642,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-assessments"
  }, {
    path: "/learn/courses/:code/competencies/",
    component: _96cd983a,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-competencies"
  }, {
    path: "/learn/courses/:code/evaluations/",
    component: _0ba3bc25,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-evaluations"
  }, {
    path: "/manage/competencies/:code/edit/",
    component: _2219a3f7,
    pathToRegexpOptions: {"strict":true},
    name: "manage-competencies-code-edit"
  }, {
    path: "/manage/courses/:code/assessments/",
    component: _5502a3e0,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-assessments"
  }, {
    path: "/manage/courses/:code/edit/",
    component: _31b90ede,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-edit"
  }, {
    path: "/manage/courses/:code/groups/",
    component: _62d38f2b,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-groups"
  }, {
    path: "/manage/courses/:code/registrations/",
    component: _30852a8e,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-registrations"
  }, {
    path: "/manage/programs/:code/edit/",
    component: _07298743,
    pathToRegexpOptions: {"strict":true},
    name: "manage-programs-code-edit"
  }, {
    path: "/manage/programs/:code/registrations/",
    component: _3a4b0898,
    pathToRegexpOptions: {"strict":true},
    name: "manage-programs-code-registrations"
  }, {
    path: "/teach/courses/:code/assessments/",
    component: _1c9e1ae0,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-assessments"
  }, {
    path: "/teach/courses/:code/calendar/",
    component: _0194a095,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-calendar"
  }, {
    path: "/teach/courses/:code/evaluations/",
    component: _46def1d6,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-evaluations"
  }, {
    path: "/teach/courses/:code/learners/",
    component: _12709cf9,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-learners"
  }, {
    path: "/manage/courses/:code/assessments/create/",
    component: _020b5eae,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-assessments-create"
  }, {
    path: "/teach/courses/:code/evaluations/create/",
    component: _8698d8b0,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-evaluations-create"
  }, {
    path: "/learn/courses/:code/assessments/:id/",
    component: _5057504a,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-assessments-id"
  }, {
    path: "/learn/courses/:code/evaluations/:id/",
    component: _54483c90,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-evaluations-id"
  }, {
    path: "/manage/courses/:code/assessments/:id/",
    component: _1b9b7e0a,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-assessments-id"
  }, {
    path: "/teach/courses/:code/assessments/:id/",
    component: _d20b4e10,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-assessments-id"
  }, {
    path: "/teach/courses/:code/evaluations/:id/",
    component: _6e9f31c1,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-evaluations-id"
  }, {
    path: "/teach/courses/:code/learners/:username/",
    component: _3e62f6df,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-learners-username"
  }, {
    path: "/learn/courses/:code/assessments/:id/requestEvaluation/",
    component: _209fb223,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-assessments-id-requestEvaluation"
  }, {
    path: "/manage/courses/:code/assessments/:id/edit/",
    component: _fcdf521e,
    pathToRegexpOptions: {"strict":true},
    name: "manage-courses-code-assessments-id-edit"
  }, {
    path: "/teach/courses/:code/evaluations/:id/edit/",
    component: _32ff356b,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-evaluations-id-edit"
  }, {
    path: "/teach/courses/:code/learners/:username/assessments/",
    component: _18a5467e,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-learners-username-assessments"
  }, {
    path: "/learn/courses/:code/assessments/:id/take/:iid?/",
    component: _7c924105,
    pathToRegexpOptions: {"strict":true},
    name: "learn-courses-code-assessments-id-take-iid"
  }, {
    path: "/teach/courses/:code/learners/:username/assessments/:id/",
    component: _0684c92e,
    pathToRegexpOptions: {"strict":true},
    name: "teach-courses-code-learners-username-assessments-id"
  }, {
    path: "/courses/:code/",
    component: _21d17205,
    pathToRegexpOptions: {"strict":true},
    name: "courses-code"
  }, {
    path: "/partners/:code/",
    component: _1ee645c8,
    pathToRegexpOptions: {"strict":true},
    name: "partners-code"
  }, {
    path: "/profiles/:username/",
    component: _47ee7b9a,
    pathToRegexpOptions: {"strict":true},
    name: "profiles-username"
  }, {
    path: "/programs/:code/",
    component: _277252e8,
    pathToRegexpOptions: {"strict":true},
    name: "programs-code"
  }, {
    path: "/profiles/:username?/edit/",
    component: _3ec8ceb9,
    pathToRegexpOptions: {"strict":true},
    name: "profiles-username-edit"
  }, {
    path: "/profiles/:username?/passwordreset/:token?/",
    component: _c7478df8,
    pathToRegexpOptions: {"strict":true},
    name: "profiles-username-passwordreset-token"
  }, {
    path: "/profiles/:username?/:emailConfirmationToken/",
    component: _39ca8770,
    pathToRegexpOptions: {"strict":true},
    name: "profiles-username-emailConfirmationToken"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
