
export default {
  name: 'SendConfirmationEmailBtn',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    usernameOrEmail: {
      type: String,
      required: true,
    },
  },
  methods: {
    done(result) {
      this.$emit('done', result)
    },
  },
}
