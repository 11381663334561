
import authentication from '@/mixins/authentication.js'

import { spaces } from '@/data/spaces.config.js'

export default {
  name: 'SpaceNavigator',
  mixins: [authentication],
  data() {
    return {
      hoveredItem: undefined,
      miniVariant: true,
    }
  },
  computed: {
    filteredSpaces() {
      return spaces
        .filter((space) => !space.roles || this.hasAnyRoles(space.roles))
        .map((space) => ({
          ...space,
          sections: space.sections.filter(
            (section) => !section.roles || this.hasAnyRoles(section.roles)
          ),
        }))
    },
    hoveredSpace() {
      return this.hoveredItem ?? this.filteredSpaces[this.selectedSpace]
    },
    selectedSpace() {
      const spacesRoot = spaces.map((s) => s.to.name).slice(1)
      const path = this.$route.path

      for (const [i, space] of spacesRoot.entries()) {
        if (path.startsWith(space)) {
          return i + 1
        }
      }
      return 0
    },
  },
  methods: {
    selectItem(index) {
      this.$router.push(this.filteredSpaces[index].to)
    },
  },
}
