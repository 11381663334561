
export default {
  name: 'PasswordField',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    type() {
      return this.visible ? 'input' : 'password'
    },
    icon() {
      return this.visible ? 'mdi-eye-off' : 'mdi-eye'
    },
  },
}
