
export default {
  name: 'SendPasswordResetEmailBtn',
  props: {
    usernameOrEmail: {
      type: String,
      required: true,
    },
  },
  methods: {
    done(result) {
      this.$emit('done', result)
    },
  },
}
