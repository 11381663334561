
export default {
  name: 'GenericBtn',
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
}
