
export default {
  name: 'SubmitBtn',
  props: {
    action: {
      type: String,
      default: 'create',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
