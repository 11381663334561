
export default {
  name: 'LanguageSwitcher',
  computed: {
    availableLocales() {
      return this.$i18n.locales
    },
    currentLocale: {
      get() {
        return this.$i18n.locale
      },
      set(value) {
        this.$i18n.setLocale(value)
      },
    },
  },
}
