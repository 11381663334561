export const AcceptBtn = () => import('../../components/base/AcceptBtn.vue' /* webpackChunkName: "components/accept-btn" */).then(c => wrapFunctional(c.default || c))
export const ActionsMenu = () => import('../../components/base/ActionsMenu.vue' /* webpackChunkName: "components/actions-menu" */).then(c => wrapFunctional(c.default || c))
export const BooleanValueIcon = () => import('../../components/base/BooleanValueIcon.vue' /* webpackChunkName: "components/boolean-value-icon" */).then(c => wrapFunctional(c.default || c))
export const CancelBtn = () => import('../../components/base/CancelBtn.vue' /* webpackChunkName: "components/cancel-btn" */).then(c => wrapFunctional(c.default || c))
export const CheckList = () => import('../../components/base/CheckList.vue' /* webpackChunkName: "components/check-list" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDialog = () => import('../../components/base/ConfirmDialog.vue' /* webpackChunkName: "components/confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const DateTimePicker = () => import('../../components/base/DateTimePicker.vue' /* webpackChunkName: "components/date-time-picker" */).then(c => wrapFunctional(c.default || c))
export const DescriptionContent = () => import('../../components/base/DescriptionContent.vue' /* webpackChunkName: "components/description-content" */).then(c => wrapFunctional(c.default || c))
export const DetailLinkBtn = () => import('../../components/base/DetailLinkBtn.vue' /* webpackChunkName: "components/detail-link-btn" */).then(c => wrapFunctional(c.default || c))
export const GenericBtn = () => import('../../components/base/GenericBtn.vue' /* webpackChunkName: "components/generic-btn" */).then(c => wrapFunctional(c.default || c))
export const ImageChooserField = () => import('../../components/base/ImageChooserField.vue' /* webpackChunkName: "components/image-chooser-field" */).then(c => wrapFunctional(c.default || c))
export const PageTitle = () => import('../../components/base/PageTitle.vue' /* webpackChunkName: "components/page-title" */).then(c => wrapFunctional(c.default || c))
export const PartnerSelectField = () => import('../../components/base/PartnerSelectField.vue' /* webpackChunkName: "components/partner-select-field" */).then(c => wrapFunctional(c.default || c))
export const PasswordField = () => import('../../components/base/PasswordField.vue' /* webpackChunkName: "components/password-field" */).then(c => wrapFunctional(c.default || c))
export const RejectBtn = () => import('../../components/base/RejectBtn.vue' /* webpackChunkName: "components/reject-btn" */).then(c => wrapFunctional(c.default || c))
export const RequestInvitationBtn = () => import('../../components/base/RequestInvitationBtn.vue' /* webpackChunkName: "components/request-invitation-btn" */).then(c => wrapFunctional(c.default || c))
export const ResetBtn = () => import('../../components/base/ResetBtn.vue' /* webpackChunkName: "components/reset-btn" */).then(c => wrapFunctional(c.default || c))
export const ResourcesList = () => import('../../components/base/ResourcesList.vue' /* webpackChunkName: "components/resources-list" */).then(c => wrapFunctional(c.default || c))
export const ScheduleTimeline = () => import('../../components/base/ScheduleTimeline.vue' /* webpackChunkName: "components/schedule-timeline" */).then(c => wrapFunctional(c.default || c))
export const SendConfirmationEmailBtn = () => import('../../components/base/SendConfirmationEmailBtn.vue' /* webpackChunkName: "components/send-confirmation-email-btn" */).then(c => wrapFunctional(c.default || c))
export const SendPasswordResetEmailBtn = () => import('../../components/base/SendPasswordResetEmailBtn.vue' /* webpackChunkName: "components/send-password-reset-email-btn" */).then(c => wrapFunctional(c.default || c))
export const StarsField = () => import('../../components/base/StarsField.vue' /* webpackChunkName: "components/stars-field" */).then(c => wrapFunctional(c.default || c))
export const StatsList = () => import('../../components/base/StatsList.vue' /* webpackChunkName: "components/stats-list" */).then(c => wrapFunctional(c.default || c))
export const StepperStep = () => import('../../components/base/StepperStep.vue' /* webpackChunkName: "components/stepper-step" */).then(c => wrapFunctional(c.default || c))
export const SubmitBtn = () => import('../../components/base/SubmitBtn.vue' /* webpackChunkName: "components/submit-btn" */).then(c => wrapFunctional(c.default || c))
export const TeachersSelectField = () => import('../../components/base/TeachersSelectField.vue' /* webpackChunkName: "components/teachers-select-field" */).then(c => wrapFunctional(c.default || c))
export const TimerInfoPanel = () => import('../../components/base/TimerInfoPanel.vue' /* webpackChunkName: "components/timer-info-panel" */).then(c => wrapFunctional(c.default || c))
export const BannerImage = () => import('../../components/cards/BannerImage.vue' /* webpackChunkName: "components/banner-image" */).then(c => wrapFunctional(c.default || c))
export const CardList = () => import('../../components/cards/CardList.vue' /* webpackChunkName: "components/card-list" */).then(c => wrapFunctional(c.default || c))
export const CourseCard = () => import('../../components/cards/CourseCard.vue' /* webpackChunkName: "components/course-card" */).then(c => wrapFunctional(c.default || c))
export const GenericCard = () => import('../../components/cards/GenericCard.vue' /* webpackChunkName: "components/generic-card" */).then(c => wrapFunctional(c.default || c))
export const GenericInfoCard = () => import('../../components/cards/GenericInfoCard.vue' /* webpackChunkName: "components/generic-info-card" */).then(c => wrapFunctional(c.default || c))
export const PartnerCard = () => import('../../components/cards/PartnerCard.vue' /* webpackChunkName: "components/partner-card" */).then(c => wrapFunctional(c.default || c))
export const ProgramCard = () => import('../../components/cards/ProgramCard.vue' /* webpackChunkName: "components/program-card" */).then(c => wrapFunctional(c.default || c))
export const ProgramCoursesInfoCard = () => import('../../components/cards/ProgramCoursesInfoCard.vue' /* webpackChunkName: "components/program-courses-info-card" */).then(c => wrapFunctional(c.default || c))
export const ProgressInfoCard = () => import('../../components/cards/ProgressInfoCard.vue' /* webpackChunkName: "components/progress-info-card" */).then(c => wrapFunctional(c.default || c))
export const AssessmentDeleteBtn = () => import('../../components/courses/AssessmentDeleteBtn.vue' /* webpackChunkName: "components/assessment-delete-btn" */).then(c => wrapFunctional(c.default || c))
export const SelectAssessmentCompetencies = () => import('../../components/courses/SelectAssessmentCompetencies.vue' /* webpackChunkName: "components/select-assessment-competencies" */).then(c => wrapFunctional(c.default || c))
export const SelectAssessmentPhases = () => import('../../components/courses/SelectAssessmentPhases.vue' /* webpackChunkName: "components/select-assessment-phases" */).then(c => wrapFunctional(c.default || c))
export const TeachingGroupsList = () => import('../../components/courses/TeachingGroupsList.vue' /* webpackChunkName: "components/teaching-groups-list" */).then(c => wrapFunctional(c.default || c))
export const CompetenciesFilter = () => import('../../components/filterbars/CompetenciesFilter.vue' /* webpackChunkName: "components/competencies-filter" */).then(c => wrapFunctional(c.default || c))
export const CoursesFilter = () => import('../../components/filterbars/CoursesFilter.vue' /* webpackChunkName: "components/courses-filter" */).then(c => wrapFunctional(c.default || c))
export const EvaluationsFilter = () => import('../../components/filterbars/EvaluationsFilter.vue' /* webpackChunkName: "components/evaluations-filter" */).then(c => wrapFunctional(c.default || c))
export const GenericFilterBar = () => import('../../components/filterbars/GenericFilterBar.vue' /* webpackChunkName: "components/generic-filter-bar" */).then(c => wrapFunctional(c.default || c))
export const ProgramsFilter = () => import('../../components/filterbars/ProgramsFilter.vue' /* webpackChunkName: "components/programs-filter" */).then(c => wrapFunctional(c.default || c))
export const ListPage = () => import('../../components/homespace/ListPage.vue' /* webpackChunkName: "components/list-page" */).then(c => wrapFunctional(c.default || c))
export const SignInBtn = () => import('../../components/homespace/SignInBtn.vue' /* webpackChunkName: "components/sign-in-btn" */).then(c => wrapFunctional(c.default || c))
export const SignUpBtn = () => import('../../components/homespace/SignUpBtn.vue' /* webpackChunkName: "components/sign-up-btn" */).then(c => wrapFunctional(c.default || c))
export const TabList = () => import('../../components/homespace/TabList.vue' /* webpackChunkName: "components/tab-list" */).then(c => wrapFunctional(c.default || c))
export const CompetenciesListInfoPanel = () => import('../../components/infopanels/CompetenciesListInfoPanel.vue' /* webpackChunkName: "components/competencies-list-info-panel" */).then(c => wrapFunctional(c.default || c))
export const CoursesListInfoPanel = () => import('../../components/infopanels/CoursesListInfoPanel.vue' /* webpackChunkName: "components/courses-list-info-panel" */).then(c => wrapFunctional(c.default || c))
export const GenericInfoPanel = () => import('../../components/infopanels/GenericInfoPanel.vue' /* webpackChunkName: "components/generic-info-panel" */).then(c => wrapFunctional(c.default || c))
export const PartnerInfoPanel = () => import('../../components/infopanels/PartnerInfoPanel.vue' /* webpackChunkName: "components/partner-info-panel" */).then(c => wrapFunctional(c.default || c))
export const ProgramInfoPanel = () => import('../../components/infopanels/ProgramInfoPanel.vue' /* webpackChunkName: "components/program-info-panel" */).then(c => wrapFunctional(c.default || c))
export const ProgramStatusInfoPanel = () => import('../../components/infopanels/ProgramStatusInfoPanel.vue' /* webpackChunkName: "components/program-status-info-panel" */).then(c => wrapFunctional(c.default || c))
export const ProgressPanel = () => import('../../components/infopanels/ProgressPanel.vue' /* webpackChunkName: "components/progress-panel" */).then(c => wrapFunctional(c.default || c))
export const ProgramPublishBtn = () => import('../../components/programs/ProgramPublishBtn.vue' /* webpackChunkName: "components/program-publish-btn" */).then(c => wrapFunctional(c.default || c))
export const ProviderInfoPanel = () => import('../../components/provider/ProviderInfoPanel.vue' /* webpackChunkName: "components/provider-info-panel" */).then(c => wrapFunctional(c.default || c))
export const TfqView = () => import('../../components/provider/tfq/View.vue' /* webpackChunkName: "components/tfq-view" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumb = () => import('../../components/BreadCrumb.vue' /* webpackChunkName: "components/bread-crumb" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const NotificationDisplayer = () => import('../../components/NotificationDisplayer.vue' /* webpackChunkName: "components/notification-displayer" */).then(c => wrapFunctional(c.default || c))
export const SpaceNavigator = () => import('../../components/SpaceNavigator.vue' /* webpackChunkName: "components/space-navigator" */).then(c => wrapFunctional(c.default || c))
export const SpaceSwitcher = () => import('../../components/SpaceSwitcher.vue' /* webpackChunkName: "components/space-switcher" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../components/UserMenu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))
export const AssessmentCompetenciesList = () => import('../../components/assessment/CompetenciesList.vue' /* webpackChunkName: "components/assessment-competencies-list" */).then(c => wrapFunctional(c.default || c))
export const AssessmentCompetenciesListBlock = () => import('../../components/assessment/CompetenciesListBlock.vue' /* webpackChunkName: "components/assessment-competencies-list-block" */).then(c => wrapFunctional(c.default || c))
export const AssessmentEvaluationsList = () => import('../../components/assessment/EvaluationsList.vue' /* webpackChunkName: "components/assessment-evaluations-list" */).then(c => wrapFunctional(c.default || c))
export const AssessmentInfoPanel = () => import('../../components/assessment/InfoPanel.vue' /* webpackChunkName: "components/assessment-info-panel" */).then(c => wrapFunctional(c.default || c))
export const AssessmentInstanceSelector = () => import('../../components/assessment/InstanceSelector.vue' /* webpackChunkName: "components/assessment-instance-selector" */).then(c => wrapFunctional(c.default || c))
export const AssessmentOpenCloseBtn = () => import('../../components/assessment/OpenCloseBtn.vue' /* webpackChunkName: "components/assessment-open-close-btn" */).then(c => wrapFunctional(c.default || c))
export const AssessmentPhaseSelect = () => import('../../components/assessment/PhaseSelect.vue' /* webpackChunkName: "components/assessment-phase-select" */).then(c => wrapFunctional(c.default || c))
export const AssessmentPhases = () => import('../../components/assessment/Phases.vue' /* webpackChunkName: "components/assessment-phases" */).then(c => wrapFunctional(c.default || c))
export const AssessmentRequestEvaluationBtn = () => import('../../components/assessment/RequestEvaluationBtn.vue' /* webpackChunkName: "components/assessment-request-evaluation-btn" */).then(c => wrapFunctional(c.default || c))
export const AssessmentSchedulePanel = () => import('../../components/assessment/SchedulePanel.vue' /* webpackChunkName: "components/assessment-schedule-panel" */).then(c => wrapFunctional(c.default || c))
export const AssessmentSelect = () => import('../../components/assessment/Select.vue' /* webpackChunkName: "components/assessment-select" */).then(c => wrapFunctional(c.default || c))
export const AssessmentShowHideBtn = () => import('../../components/assessment/ShowHideBtn.vue' /* webpackChunkName: "components/assessment-show-hide-btn" */).then(c => wrapFunctional(c.default || c))
export const AssessmentTakesInfoPanel = () => import('../../components/assessment/TakesInfoPanel.vue' /* webpackChunkName: "components/assessment-takes-info-panel" */).then(c => wrapFunctional(c.default || c))
export const AssessmentTakesStatus = () => import('../../components/assessment/TakesStatus.vue' /* webpackChunkName: "components/assessment-takes-status" */).then(c => wrapFunctional(c.default || c))
export const AssessmentsCoverage = () => import('../../components/assessments/Coverage.vue' /* webpackChunkName: "components/assessments-coverage" */).then(c => wrapFunctional(c.default || c))
export const AssessmentsInfoCard = () => import('../../components/assessments/InfoCard.vue' /* webpackChunkName: "components/assessments-info-card" */).then(c => wrapFunctional(c.default || c))
export const AssessmentsInfoPanel = () => import('../../components/assessments/InfoPanel.vue' /* webpackChunkName: "components/assessments-info-panel" */).then(c => wrapFunctional(c.default || c))
export const AssessmentsList = () => import('../../components/assessments/List.vue' /* webpackChunkName: "components/assessments-list" */).then(c => wrapFunctional(c.default || c))
export const AssessmentsTakesStatus = () => import('../../components/assessments/TakesStatus.vue' /* webpackChunkName: "components/assessments-takes-status" */).then(c => wrapFunctional(c.default || c))
export const AssessmentsTimeline = () => import('../../components/assessments/Timeline.vue' /* webpackChunkName: "components/assessments-timeline" */).then(c => wrapFunctional(c.default || c))
export const ChecklistDefineBtn = () => import('../../components/checklist/DefineBtn.vue' /* webpackChunkName: "components/checklist-define-btn" */).then(c => wrapFunctional(c.default || c))
export const CompetenciesList = () => import('../../components/competencies/List.vue' /* webpackChunkName: "components/competencies-list" */).then(c => wrapFunctional(c.default || c))
export const CompetenciesProgress = () => import('../../components/competencies/Progress.vue' /* webpackChunkName: "components/competencies-progress" */).then(c => wrapFunctional(c.default || c))
export const CompetencyCheckList = () => import('../../components/competency/CheckList.vue' /* webpackChunkName: "components/competency-check-list" */).then(c => wrapFunctional(c.default || c))
export const CompetencyInfoPanel = () => import('../../components/competency/InfoPanel.vue' /* webpackChunkName: "components/competency-info-panel" */).then(c => wrapFunctional(c.default || c))
export const CompetencyListItem = () => import('../../components/competency/ListItem.vue' /* webpackChunkName: "components/competency-list-item" */).then(c => wrapFunctional(c.default || c))
export const CompetencyRemoveBtn = () => import('../../components/competency/RemoveBtn.vue' /* webpackChunkName: "components/competency-remove-btn" */).then(c => wrapFunctional(c.default || c))
export const CourseAcceptInvitationBtn = () => import('../../components/course/AcceptInvitationBtn.vue' /* webpackChunkName: "components/course-accept-invitation-btn" */).then(c => wrapFunctional(c.default || c))
export const CourseCompetenciesList = () => import('../../components/course/CompetenciesList.vue' /* webpackChunkName: "components/course-competencies-list" */).then(c => wrapFunctional(c.default || c))
export const CourseCompetenciesProgressList = () => import('../../components/course/CompetenciesProgressList.vue' /* webpackChunkName: "components/course-competencies-progress-list" */).then(c => wrapFunctional(c.default || c))
export const CourseInfoPanel = () => import('../../components/course/InfoPanel.vue' /* webpackChunkName: "components/course-info-panel" */).then(c => wrapFunctional(c.default || c))
export const CourseRegisterBtn = () => import('../../components/course/RegisterBtn.vue' /* webpackChunkName: "components/course-register-btn" */).then(c => wrapFunctional(c.default || c))
export const CourseSchedulePanel = () => import('../../components/course/SchedulePanel.vue' /* webpackChunkName: "components/course-schedule-panel" */).then(c => wrapFunctional(c.default || c))
export const CourseSendInvitationBtn = () => import('../../components/course/SendInvitationBtn.vue' /* webpackChunkName: "components/course-send-invitation-btn" */).then(c => wrapFunctional(c.default || c))
export const CourseStatusInfoPanel = () => import('../../components/course/StatusInfoPanel.vue' /* webpackChunkName: "components/course-status-info-panel" */).then(c => wrapFunctional(c.default || c))
export const EvaluationCorrectBtn = () => import('../../components/evaluation/CorrectBtn.vue' /* webpackChunkName: "components/evaluation-correct-btn" */).then(c => wrapFunctional(c.default || c))
export const EvaluationDeleteBtn = () => import('../../components/evaluation/DeleteBtn.vue' /* webpackChunkName: "components/evaluation-delete-btn" */).then(c => wrapFunctional(c.default || c))
export const EvaluationInfoPanel = () => import('../../components/evaluation/InfoPanel.vue' /* webpackChunkName: "components/evaluation-info-panel" */).then(c => wrapFunctional(c.default || c))
export const EvaluationPublishBtn = () => import('../../components/evaluation/PublishBtn.vue' /* webpackChunkName: "components/evaluation-publish-btn" */).then(c => wrapFunctional(c.default || c))
export const EvaluationRequestAcceptBtn = () => import('../../components/evaluation/RequestAcceptBtn.vue' /* webpackChunkName: "components/evaluation-request-accept-btn" */).then(c => wrapFunctional(c.default || c))
export const EvaluationRequestDeleteBtn = () => import('../../components/evaluation/RequestDeleteBtn.vue' /* webpackChunkName: "components/evaluation-request-delete-btn" */).then(c => wrapFunctional(c.default || c))
export const EvaluationRequestRejectBtn = () => import('../../components/evaluation/RequestRejectBtn.vue' /* webpackChunkName: "components/evaluation-request-reject-btn" */).then(c => wrapFunctional(c.default || c))
export const EvaluationStatusChip = () => import('../../components/evaluation/StatusChip.vue' /* webpackChunkName: "components/evaluation-status-chip" */).then(c => wrapFunctional(c.default || c))
export const EvaluationStatusHistory = () => import('../../components/evaluation/StatusHistory.vue' /* webpackChunkName: "components/evaluation-status-history" */).then(c => wrapFunctional(c.default || c))
export const EvaluationsInfoCard = () => import('../../components/evaluations/InfoCard.vue' /* webpackChunkName: "components/evaluations-info-card" */).then(c => wrapFunctional(c.default || c))
export const EvaluationsList = () => import('../../components/evaluations/List.vue' /* webpackChunkName: "components/evaluations-list" */).then(c => wrapFunctional(c.default || c))
export const EventsInfoCard = () => import('../../components/events/InfoCard.vue' /* webpackChunkName: "components/events-info-card" */).then(c => wrapFunctional(c.default || c))
export const EventsCalendar = () => import('../../components/events/calendar.vue' /* webpackChunkName: "components/events-calendar" */).then(c => wrapFunctional(c.default || c))
export const FormAssessment = () => import('../../components/form/Assessment.vue' /* webpackChunkName: "components/form-assessment" */).then(c => wrapFunctional(c.default || c))
export const FormCompetency = () => import('../../components/form/Competency.vue' /* webpackChunkName: "components/form-competency" */).then(c => wrapFunctional(c.default || c))
export const FormCourse = () => import('../../components/form/Course.vue' /* webpackChunkName: "components/form-course" */).then(c => wrapFunctional(c.default || c))
export const FormEvaluation = () => import('../../components/form/Evaluation.vue' /* webpackChunkName: "components/form-evaluation" */).then(c => wrapFunctional(c.default || c))
export const FormPartner = () => import('../../components/form/Partner.vue' /* webpackChunkName: "components/form-partner" */).then(c => wrapFunctional(c.default || c))
export const FormProgram = () => import('../../components/form/Program.vue' /* webpackChunkName: "components/form-program" */).then(c => wrapFunctional(c.default || c))
export const FormSignIn = () => import('../../components/form/SignIn.vue' /* webpackChunkName: "components/form-sign-in" */).then(c => wrapFunctional(c.default || c))
export const FormUser = () => import('../../components/form/User.vue' /* webpackChunkName: "components/form-user" */).then(c => wrapFunctional(c.default || c))
export const GroupsInfoCard = () => import('../../components/groups/InfoCard.vue' /* webpackChunkName: "components/groups-info-card" */).then(c => wrapFunctional(c.default || c))
export const GroupsWorkingList = () => import('../../components/groups/WorkingList.vue' /* webpackChunkName: "components/groups-working-list" */).then(c => wrapFunctional(c.default || c))
export const InstanceDeleteBtn = () => import('../../components/instance/DeleteBtn.vue' /* webpackChunkName: "components/instance-delete-btn" */).then(c => wrapFunctional(c.default || c))
export const LearnerInfoPanel = () => import('../../components/learner/InfoPanel.vue' /* webpackChunkName: "components/learner-info-panel" */).then(c => wrapFunctional(c.default || c))
export const LearnerSelect = () => import('../../components/learner/Select.vue' /* webpackChunkName: "components/learner-select" */).then(c => wrapFunctional(c.default || c))
export const LearnersInfoCard = () => import('../../components/learners/InfoCard.vue' /* webpackChunkName: "components/learners-info-card" */).then(c => wrapFunctional(c.default || c))
export const LearnersList = () => import('../../components/learners/List.vue' /* webpackChunkName: "components/learners-list" */).then(c => wrapFunctional(c.default || c))
export const LearningOutcomeChip = () => import('../../components/learningOutcome/Chip.vue' /* webpackChunkName: "components/learning-outcome-chip" */).then(c => wrapFunctional(c.default || c))
export const LearningOutcomesList = () => import('../../components/learningOutcomes/List.vue' /* webpackChunkName: "components/learning-outcomes-list" */).then(c => wrapFunctional(c.default || c))
export const RegistrationInfoPanel = () => import('../../components/registration/InfoPanel.vue' /* webpackChunkName: "components/registration-info-panel" */).then(c => wrapFunctional(c.default || c))
export const RegistrationsInfoCard = () => import('../../components/registrations/InfoCard.vue' /* webpackChunkName: "components/registrations-info-card" */).then(c => wrapFunctional(c.default || c))
export const RegistrationsList = () => import('../../components/registrations/List.vue' /* webpackChunkName: "components/registrations-list" */).then(c => wrapFunctional(c.default || c))
export const ResourcesInfoCard = () => import('../../components/resources/InfoCard.vue' /* webpackChunkName: "components/resources-info-card" */).then(c => wrapFunctional(c.default || c))
export const SelectCompetenciesLearningOutcomes = () => import('../../components/select/CompetenciesLearningOutcomes.vue' /* webpackChunkName: "components/select-competencies-learning-outcomes" */).then(c => wrapFunctional(c.default || c))
export const SelectCourseCompetencies = () => import('../../components/select/CourseCompetencies.vue' /* webpackChunkName: "components/select-course-competencies" */).then(c => wrapFunctional(c.default || c))
export const SelectCourseTeachingGroups = () => import('../../components/select/CourseTeachingGroups.vue' /* webpackChunkName: "components/select-course-teaching-groups" */).then(c => wrapFunctional(c.default || c))
export const SelectCourseWorkingGroups = () => import('../../components/select/CourseWorkingGroups.vue' /* webpackChunkName: "components/select-course-working-groups" */).then(c => wrapFunctional(c.default || c))
export const SelectProgramCourses = () => import('../../components/select/ProgramCourses.vue' /* webpackChunkName: "components/select-program-courses" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
