
import { DateTime } from 'luxon'

import datetime from '@/mixins/datetime.js'

export default {
  name: 'DateTimePicker',
  mixins: [datetime],
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      datetime: null,
      showMenu: false,
    }
  },
  computed: {
    date: {
      get() {
        return this.datetime?.toISODate()
      },
      set(value) {
        const date = DateTime.fromFormat(value, 'yyyy-LL-dd')

        if (!this.datetime) {
          this.datetime = date
          return
        }

        this.datetime = this.datetime.set({
          day: date.day,
          month: date.month,
          year: date.year,
        })
      },
    },
    time: {
      get() {
        return (
          this.datetime?.toISOTime({
            includeOffset: false,
            suppressSeconds: true,
          }) ?? '00:00'
        )
      },
      set(value) {
        const time = DateTime.fromFormat(value, 'hh:mm')
        this.datetime = this.datetime.set({
          hour: time.hour,
          minute: time.minute,
        })
      },
    },
    dateSelected() {
      return this.date
    },
  },
  watch: {
    value: {
      handler(value) {
        const datetime = DateTime.fromISO(value)
        if (!datetime.invalid) {
          this.datetime = datetime
        }
      },
      immediate: true,
    },
  },
  methods: {
    clear() {
      this.datetime = null
      this.$emit('input', '')
    },
    resetPicker() {
      this.activeTab = 0
      this.showMenu = false
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }
    },
    showTimePicker() {
      this.activeTab = 1
    },
    submit() {
      this.resetPicker()
      this.$emit('input', this.datetime?.toISO() ?? '')
    },
  },
}
