
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'SignInForm',
  components: { ValidationObserver },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmationEmailSent: false,
      formBusy: false,
      formError: null,
      password: '',
      passwordResetEmailSent: false,
      showPasswordResetBtn: false,
      showResendBtn: false,
      usernameOrEmail: '',
    }
  },
  watch: {
    formBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', newVal)
      }
    },
  },
  methods: {
    reset() {
      this.resetForm()
      this.$emit('reset')
    },
    resetForm() {
      this.formError = null
      this.password = ''
      this.usernameOrEmail = ''
      this.$refs.form.reset()
    },
    async signIn() {
      this.formBusy = true

      try {
        const response = await this.$auth.loginWith('graphql', {
          usernameOrEmail: this.usernameOrEmail,
          password: this.password,
        })

        if (response) {
          this.$notificationManager.displaySuccessMessage(
            this.$t('success.SIGN_IN_SUCCESSFUL')
          )
          this.resetForm()
          return
        }
      } catch (err) {
        if (err.graphQLErrors?.length) {
          const errKey = err.graphQLErrors[0].message
          this.showPasswordResetBtn = errKey === 'INVALID_CREDENTIALS'
          this.showResendBtn = errKey === 'UNCONFIRMED_EMAIL_ADDRESS'
          this.formError = `error.${errKey}`
        }
      }

      if (!this.formError) {
        this.formError = 'error._'
      }
      this.formBusy = false
    },
    onConfirmationEmailSent({ data: { sendConfirmationEmail: result } }) {
      if (result) {
        this.$notificationManager.displaySuccessMessage(
          this.$t('success.CONFIRMATION_EMAIL_SEND')
        )
        this.confirmationEmailSent = true
      }
    },
    onPasswordResetEmailSent({ data: { sendPasswordResetEmail: result } }) {
      if (result) {
        this.$notificationManager.displaySuccessMessage(
          this.$t('success.PASSWORD_RESET_EMAIL_RESEND')
        )
        this.passwordResetEmailSent = true
      }
    },
  },
}
