
export default {
  name: 'StarsField',
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    stars: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
