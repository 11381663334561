
import { ValidationObserver } from 'vee-validate'
import signUpGql from '~/gql/signUp.gql'

export default {
  name: 'SignUpBtn',
  components: { ValidationObserver },
  data() {
    return {
      dialog: false,
      email: '',
      formBusy: false,
      formError: null,
      password: '',
    }
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.reset()
      }
    },
  },
  methods: {
    reset() {
      this.dialog = false
      this.email = ''
      this.formError = null
      this.password = ''
      this.$refs.form.reset()
    },
    async signUp() {
      this.formBusy = true

      try {
        const response = await this.$apollo
          .mutate({
            mutation: signUpGql,
            variables: {
              email: this.email,
              password: this.password,
            },
          })
          .then(({ data }) => data && data.signUp)

        if (response) {
          this.reset()
          this.$notificationManager.displaySuccessMessage(
            this.$t('success.SIGN_UP_SUCCESSFUL')
          )
          return
        }
      } catch (err) {
        if (err.graphQLErrors?.length) {
          this.formError = `error.${err.graphQLErrors[0].message}`
        }
      }

      if (!this.formError) {
        this.formError = 'error._'
      }
      this.formBusy = false
    },
  },
}
